import React, { useEffect, useState } from "react";
import { Button, message } from "antd";
import TradingCandle from "../../Assets/Images/connectmodal.png";
import Logo from "../../Assets/Svg/Logo.png";
import { ConnectWallet, lightTheme, useAddress } from "@thirdweb-dev/react";
import { Modal, Toast } from "react-bootstrap";
import { ethers } from "ethers"
import WhiteListAbi from "../../Config/WhiteListAbi.json";
import { WhiteListContractInstance } from "../../Config/config.js"
import Loader from "../../common/Loader.js";

const ConnectWalletModal = () => {
  const address = useAddress();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWhislisted, setIsWhislisted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [whiteListedAddress, setWhiteListedAddress] = useState([{
    isVIP:false,
    isWhitelisted:false,
    account:"",
    timestamp:"",
  }]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const Walletaddress = localStorage.getItem("walletAddress");

  useEffect(() => {
    if (!loading) {
      if (address) {
        if (whiteListedAddress.some(user => user.account.toLowerCase() === address.toLowerCase())) {
          document.getElementById('root').style.filter = "none";
          setIsModalOpen(false);
          setIsWhislisted(true);
        } else {
          document.getElementById('root').style.filter = "blur(5px)";
          message.error("You are not whitelisted!");
          setIsModalOpen(true);
          setIsWhislisted(false);
        }
      } else {
        setIsModalOpen(true);
        document.getElementById('root').style.filter = "blur(5px)";
      }
    }

  }, [address, whiteListedAddress, loading]);


  const fetchWhiteListedUsers = async () => {
    try {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const ContractInstance = new ethers.Contract(WhiteListContractInstance, WhiteListAbi, signer);
      const addresses = await ContractInstance.getAllWhitelistedAddresses();
      const formattedAddresses = addresses.map(item => ({
        isVIP:item[0],
        isWhitelisted:item[1],
        account: item[2],                 // The account address
        timestamp: item[3].toString()     // Convert the timestamp BigNumber to a string
      }));
      setWhiteListedAddress(formattedAddresses);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //  message.error("Something Went Wrong!")
      console.log("error", error)
    }
  }

  useEffect(() => {
      fetchWhiteListedUsers();
  }, [address])

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {
        !address ?
          <Button className="ms-3 commonBtn p-3" onClick={showModal}>
            Connect Wallet
          </Button> :
          <ConnectWallet
            style={{ height: "45px" }}
            className="ms-3"
            hideTestnetFaucet={true}
            switchToActiveChain={true}
            theme={lightTheme({})}
            btnTitle={"Connect wallet"}
            modalTitle={"Plutus"}
            modalSize={"compact"}
            modalTitleIconUrl={Logo}
            showThirdwebBranding={false}
          />
      }
      <Modal
        show={isModalOpen}
        centered
        className="text-center"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <img src={Logo} alt="" className="img-fluid mb-2" width={50} />
          <h3>Crypto Exchange Login</h3>
          {/* <span>We Secure Your Digital Assets</span> */}
          {isWhislisted ? <span>We Secure Your Digital Assets</span> : <span style={{ color: "red" }}>You Are Not Connected With WhiteListed Wallet!</span>}
          <img src={TradingCandle} alt="" width={455} className="img-fluid" />
          <div className="d-flex justify-content-center mt-3">
            <ConnectWallet
              className=" w-50"
              hideTestnetFaucet={true}
              switchToActiveChain={true}
              theme={lightTheme({})}
              btnTitle={"Connect wallet"}
              modalTitle={"Plutus"}
              modalSize={"compact"}
              modalTitleIconUrl={Logo}
              showThirdwebBranding={false}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConnectWalletModal;
